import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "C:/Users/chatp/Projects/farming-season/doc/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "play-on-smartphone"
    }}>{`Play on Smartphone`}</h1>
    <hr></hr>
    <h3 {...{
      "id": "creating-username--password"
    }}>{`Creating username & password`}</h3>
    <p>{`You can set username and password from the dashboard ( Account Setting menu )`}</p>
    <p><img alt="acc" src={require("./public/images/account-1.png")} /></p>
    <h3 {...{
      "id": "login-with-username--password"
    }}>{`Login with username & password`}</h3>
    <p>{`When you enter the game from your smartphone, you can login with the username and the password you set from the dashboard.`}</p>
    <p><img alt="acc" src={require("./public/images/account-2.png")} /></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      